import { useMemo } from "react";

import "./style.scss";

function FormItem({ label, children }) {
  const labelElements = useMemo(() => {
    const labelLines = label.split("\\n");
    return labelLines.map((labelLine) => labelLine.split(""));
  }, [label]);

  return (
    <div className="form-item">
      <div className="form-item__label">
        {labelElements.map((labelElement, i) => (
          <p key={`form-item-${i}`}>
            {labelElement.map((labelElementString, si) => (
              <span key={`form-item-str-${si}`}>{labelElementString}</span>
            ))}
          </p>
        ))}
      </div>
      <div className="form-item__content">{children}</div>
    </div>
  );
}

export default FormItem;
