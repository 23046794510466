import { useMemo } from "react";

import "./style.scss";

function ResultItem({ label, unit, children }) {
  const labelElements = useMemo(() => {
    const labelLines = label.split("\\n");
    return labelLines.map((labelLine) => labelLine.split(""));
  }, [label]);
  return (
    <div className="result-item">
      <div className="result-item__label">
        {labelElements.map((labelElement, i) => (
          <p key={`result-item-${i}`}>
            {labelElement.map((labelElementString, si) => (
              <span key={`result-item-str-${si}`}>{labelElementString}</span>
            ))}
          </p>
        ))}
      </div>
      <div className="result-item__content">{children}</div>
      {unit && <div className="result-item__unit">{unit}</div>}
    </div>
  );
}

export default ResultItem;
