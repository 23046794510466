import { useState, useCallback, useMemo, useEffect } from "react";
import { isEqual } from "lodash";

import axios from "../utils/axios";

const defaultOptions = {
  enabled: true,
  initialData: {},
};
const useAxiosQuery = (url, params, options) => {
  const localOptions = useMemo(
    () => ({ ...defaultOptions, ...(options || {}) }),
    [options]
  );
  const [localParams, setLocalParams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(localOptions.initialData);
  const [error, setError] = useState(null);

  const fetch = useCallback(
    async (_url, _params) => {
      if (isLoading) return;
      try {
        setIsLoading(true);
        setError(null);
        setData(localOptions.initialData);

        const res = await axios.get(_url, { params: _params });
        setData(res.data);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading]
  );

  useEffect(() => {
    if (!isEqual(localParams, params)) {
      setLocalParams(params);
    }
    if (url && localOptions.enabled) {
      fetch(url, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params, localOptions.enabled]);

  return {
    isLoading,
    fetch,
    data,
    error,
  };
};

export default useAxiosQuery;
