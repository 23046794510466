import { useMemo } from "react";

import "./style.scss";

function Divider({ label }) {
  const labelElements = useMemo(() => {
    const labelLines = label.split("\\n");
    return labelLines.map((labelLine) => labelLine.split(""));
  }, [label]);

  return (
    <div className="divider">
      <div className="divider__label">
        {labelElements.map((labelElement, i) => (
          <p key={`divider-item-${i}`}>
            {labelElement.map((labelElementString, si) => (
              <span key={`divider-item-str-${si}`}>{labelElementString}</span>
            ))}
          </p>
        ))}
      </div>
      <div className="divider__line"></div>
    </div>
  );
}

export default Divider;
