import { PrimeReactProvider } from "primereact/api";

import "./App.css";

import MainScreen from "./screens/MainScreen";

function App() {
  return (
    <div className="App">
      <PrimeReactProvider>
        <MainScreen />
      </PrimeReactProvider>
    </div>
  );
}

export default App;
