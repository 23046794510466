import { useState, useMemo, useCallback, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import FormItem from "../../components/FormItem";
import ResultItem from "../../components/ResultItem";
import Divider from "../../components/Divider";

import useAxiosQuery from "../../hooks/useAxiosQuery";

import "./style.scss";

const emptyOption = [
  {
    cropsName: "없음",
    id: "Empty",
    disabled: true,
  },
];

function MainScreen() {
  const optionParams = useMemo(() => ({}), []);

  const [calcParams, setCalcParams] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      classificationId: null,
      parentCropsId: null,
      cropsId: null,
      parentOriginId: null,
      originId: null,
      parentDestinationId: null,
      destinationId: null,
    },
  });

  const [
    watchedParentCropsId,
    watchedParentOriginId,
    watchedParentDestinationId,
  ] = useWatch({
    control,
    name: ["parentCropsId", "parentOriginId", "parentDestinationId"],
    defaultValue: null,
  });

  const { data: crops } = useAxiosQuery("/carbon/crop", optionParams, {
    initialData: [],
  });

  const { data: locations } = useAxiosQuery("/carbon/location", optionParams, {
    enabled: true,
    initialData: [...emptyOption],
  });

  const {
    data: calcResult,
    error: calcError,
    isLoading,
  } = useAxiosQuery("/carbon/calu", calcParams, {
    enabled: !!calcParams,
    initialData: {},
  });

  useEffect(() => {
    if (calcError) {
      setCalcParams(null);
    }
  }, [calcError]);

  const childrenCrops = useMemo(() => {
    const children =
      crops.find((crop) => crop.id === watchedParentCropsId)?.children ?? [];
    return children.length
      ? children
      : [
          {
            cropsName: "없음",
            id: "Empty",
            disabled: true,
          },
        ];
  }, [crops, watchedParentCropsId]);

  const childrenOrigins = useMemo(() => {
    const children =
      locations.find((location) => location.id === watchedParentOriginId)
        ?.children ?? [];
    return children.length
      ? children
      : [
          {
            locationName: "없음",
            id: "Empty",
            disabled: true,
          },
        ];
  }, [locations, watchedParentOriginId]);

  const childrenDestinations = useMemo(() => {
    const children =
      locations.find((location) => location.id === watchedParentDestinationId)
        ?.children ?? [];
    return children.length
      ? children
      : [
          {
            locationName: "없음",
            id: "Empty",
            disabled: true,
          },
        ];
  }, [locations, watchedParentDestinationId]);

  const formatPercent = useCallback((percent) => {
    if (typeof percent === "number") {
      return percent === 0 ? 0 : percent.toFixed(4);
    } else {
      return percent;
    }
  }, []);

  useEffect(() => {
    setValue("cropsId", null);
  }, [setValue, watchedParentCropsId]);

  useEffect(() => {
    setValue("originId", null);
  }, [setValue, watchedParentOriginId]);

  useEffect(() => {
    setValue("destinationId", null);
  }, [setValue, watchedParentDestinationId]);

  const onSubmit = (data) => {
    setCalcParams({ ...data });
  };

  return (
    <main className="main-screen">
      <h1>농축산물 탄소배출량 예측 및 추적 서비스</h1>
      <section className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* 구분 */}
          <Controller
            name="parentCropsId"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <FormItem label="구분">
                <Dropdown
                  className="p-inputtext-sm"
                  style={{ width: "245px" }}
                  optionValue="id"
                  optionLabel="cropsName"
                  placeholder="구분을 선택하세요."
                  options={crops}
                  {...field}
                />
              </FormItem>
            )}
          />

          {/* 품목 */}
          <Controller
            name="cropsId"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <FormItem label="품목">
                <Dropdown
                  className="p-inputtext-sm"
                  style={{ width: "245px" }}
                  optionValue="id"
                  optionLabel="cropsName"
                  placeholder="품목을 선택하세요."
                  options={childrenCrops}
                  {...field}
                />
              </FormItem>
            )}
          />

          {/* 원산지 */}
          <FormItem label="원산지">
            <div style={{ display: "flex", gap: "5px" }}>
              <Controller
                name="parentOriginId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Dropdown
                    className="p-inputtext-sm"
                    style={{ width: "120px" }}
                    optionValue="id"
                    optionLabel="locationName"
                    placeholder="선택하세요."
                    options={locations}
                    {...field}
                  />
                )}
              />
              <Controller
                name="originId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Dropdown
                    className="p-inputtext-sm"
                    style={{ width: "120px" }}
                    optionValue="id"
                    optionLabel="locationName"
                    placeholder="선택하세요."
                    options={childrenOrigins}
                    {...field}
                  />
                )}
              />
            </div>
          </FormItem>

          {/* 도착지 */}
          <FormItem label="도착지">
            <div style={{ display: "flex", gap: "5px" }}>
              <Controller
                name="parentDestinationId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Dropdown
                    className="p-inputtext-sm"
                    style={{ width: "120px" }}
                    optionValue="id"
                    optionLabel="locationName"
                    placeholder="선택하세요."
                    options={locations}
                    {...field}
                  />
                )}
              />
              <Controller
                name="destinationId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Dropdown
                    className="p-inputtext-sm"
                    style={{ width: "120px" }}
                    optionValue="id"
                    optionLabel="locationName"
                    placeholder="선택하세요."
                    options={childrenDestinations}
                    {...field}
                  />
                )}
              />
            </div>
          </FormItem>

          {Object.values(errors).length > 0 ? (
            <p className="error-message">항목을 선택해주세요.</p>
          ) : null}
          {calcError ? (
            <p className="error-message">
              {calcError?.response?.status === 400
                ? calcError?.response?.data
                : "에러가 발생했습니다."}
            </p>
          ) : null}

          <div className="button-container">
            <Button
              type="submit"
              label={isLoading ? "계산중..." : "계산하기"}
              disabled={isLoading}
              style={{ backgroundColor: "#6b8cc9", border: "#6b8cc9" }}
            />
          </div>
        </form>
      </section>
      <section className="result">
        <ResultItem label="품목">{calcResult.productName}</ResultItem>
        <ResultItem label="단위">1kg</ResultItem>
        <ResultItem label="출발지">{calcResult.originName}</ResultItem>
        <ResultItem label="도착지">{calcResult.destinationName}</ResultItem>
        <Divider label="탄소배출량" />
        <ResultItem label="생산">
          {formatPercent(calcResult.production)}
        </ResultItem>
        <ResultItem label="포장">
          {formatPercent(calcResult.packaging)}
        </ResultItem>
        <ResultItem label="유통">
          {formatPercent(calcResult.distribution)}
        </ResultItem>
        <Divider label="탄소배출량\n합계" />
        <ResultItem label="" unit="kg">
          {formatPercent(calcResult.total_emission)}
        </ResultItem>
      </section>
    </main>
  );
}

export default MainScreen;
